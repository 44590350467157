import type { MetaFunction } from '@remix-run/node';
import type { LocalBusiness, PostalAddress } from 'schema-dts';
import type { loader } from '~/modules/professional/page/loaders/professional-page-loader.server';
import type { StaticParentLoaders } from '../util';
import { getMetaBaseUrl } from '../util/create-meta-base-url';
import type { LibraryLocation } from '@archipro-website/top-navigation/generated/graphql';

type AddressLocation = Pick<
    LibraryLocation,
    | 'Address1'
    | 'Address2'
    | 'Suburb'
    | 'City'
    | 'Region'
    | 'State'
    | 'Country'
    | 'PostCode'
    | 'Phone'
>;

export const createProfessionalSchema: MetaFunction<
    typeof loader,
    StaticParentLoaders
> = (props) => {
    const { data } = props;
    if (!data) return [];
    const { initial, localisation } = data;

    const baseUrl = getMetaBaseUrl(props);

    // if selectedAddress length is 0, add a default empty address to avoid Google complain
    const locations: AddressLocation[] = JSON.parse(
        JSON.stringify(initial.LibraryLocations)
    );
    if (!locations.length) {
        locations.push({
            Country: initial.Country,
        });
    }

    const selectedAddress: PostalAddress[] = locations.map((loc) => {
        return createPostalAddress(loc, initial.Country);
    });

    let image =
        initial.Banners.edges?.[0]?.node.LibraryImageFile?.DetailBannerStandard
            .Desktop ?? initial.FacebookPreviewImage;
    if (image) {
        image = baseUrl + image;
    }

    const professionalSchema = constructProfessionalSchema({
        name: initial.Title,
        ...(localisation?.Description && {
            description: localisation.Description,
        }),
        url: baseUrl + initial.Link,
        ...(selectedAddress.length > 0 && { address: selectedAddress }),
        logo: baseUrl + initial.CompanyLogo,
        ...(image && { image }),
    });

    return [professionalSchema];
};

export const createPostalAddress = (
    location: AddressLocation,
    defaultCountry?: string
): PostalAddress => {
    const streetAddress = [location.Address1, location.Address2]
        .filter((value) => !!value)
        .join(', ');

    const addressLocality = location.Suburb || location.City;
    const addressCountry = location.Country || defaultCountry;
    const addressRegion = location.Region;

    return {
        '@type': 'PostalAddress',
        ...(streetAddress && { streetAddress }),
        ...(addressLocality && { addressLocality }),
        ...(addressRegion && { addressRegion }),
        ...(location.PostCode && { postalCode: location.PostCode }),
        ...(addressCountry && { addressCountry }),
        ...(location.Phone && { telephone: location.Phone }),
    };
};

interface ProfessionalSchemaProps {
    name: string;
    description?: string;
    url: string;
    image?: string;
    logo: string;
    address?: PostalAddress[];
}

const constructProfessionalSchema = (
    prof: ProfessionalSchemaProps
): Omit<LocalBusiness, 'string'> => {
    return {
        '@type': 'LocalBusiness',
        ...prof,
    };
};
